<template>
  <div>
    <a-modal
      width="700px"
      :title="Title"
      :visible="visible"
      :ok-button-props="{ danger: true }"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="cancel"
    >
      <a-form
        ref="formRef"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }"
        :model="formState"
        :rules="rules"
      >
        <a-form-item label="省市区：" name="ProvincesMunicipalities">
          <a-cascader
            v-model:value="formState.ProvincesMunicipalities"
            :options="provinceOption"
            :field-names="{
              label: 'name',
              value: 'value',
              children: 'children',
            }"
            placeholder="请选择"
            change-on-select
          />
        </a-form-item>
        <a-form-item label="详细地址：" name="detailInfo">
          <a-textarea v-model:value="formState.detailInfo" />
        </a-form-item>
        <a-form-item label="电话号：" name="telNum">
          <a-input v-model:value="formState.telNum" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import {
  DistrictController_findByTree,
  UserAddressController_post,
  UserAddressController_put,
} from "@/api/mallManagement/placeOfShipmentManagement";
const form = {
  delFlag: 0,
  ProvincesMunicipalities: [],
  detailInfo: undefined,
  telNum: undefined,
};
export default defineComponent({
  name: "index",
  props: {
    Title: {
      type: String,
      required: false,
    },
    FormData: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      provinceOption: [],
      visible: false,
      formState: {
        ...form,
      },
      rules: {
        detailInfo: [
          { required: true, message: "不能为空", trigger: "change" },
        ],
        telNum: [{ required: true, message: "不能为空", trigger: "change" }],
        ProvincesMunicipalities: [
          {
            required: true,
            type: "array",
            message: "不能为空",
            trigger: "change",
          },
        ],
      },
      editor: false,
    };
  },
  methods: {
    cancel() {
      this.$refs.formRef.resetFields();
      this.formState = { ...form };
      this.editor = false;
      this.visible = false;
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        if (!this.editor) {
          const params = {
            ...this.formState,
            provinceName: this.formState.ProvincesMunicipalities[0]
              ? this.formState.ProvincesMunicipalities[0]
              : null,
            cityName: this.formState.ProvincesMunicipalities[1]
              ? this.formState.ProvincesMunicipalities[1]
              : null,
            countyName: this.formState.ProvincesMunicipalities[2]
              ? this.formState.ProvincesMunicipalities[2]
              : null,
          };
          UserAddressController_post(params).then((res) => {
            if (res) {
              this.visible = false;
              this.confirmLoading = false;
              this.$message.success("操作成功");
              this.cancel();
              this.$parent.refresh();
            }
          });
        } else {
          const params = {
            ...this.formState,
            provinceName: this.formState.ProvincesMunicipalities[0]
              ? this.formState.ProvincesMunicipalities[0]
              : "",
            cityName: this.formState.ProvincesMunicipalities[1]
              ? this.formState.ProvincesMunicipalities[1]
              : "",
            countyName: this.formState.ProvincesMunicipalities[2]
              ? this.formState.ProvincesMunicipalities[2]
              : "",
          };
          UserAddressController_put(params).then((res) => {
            if (res) {
              this.visible = false;
              this.confirmLoading = false;
              this.$message.success("操作成功");
              this.cancel();
              this.$parent.refresh();
            }
          });
        }
      });
    },
    showModal(val) {
      DistrictController_findByTree().then((res) => {
        this.provinceOption = res.data;
      });
      this.visible = true;
      if (val) {
        this.editor = true;
        this.formState = { ...form, ...val };
        this.formState.ProvincesMunicipalities = [];
        this.formState.ProvincesMunicipalities.push(
          this.formState.provinceName
        );
        if (this.formState.cityName) {
          this.formState.ProvincesMunicipalities.push(this.formState.cityName);
        }
        if (this.formState.countyName) {
          this.formState.ProvincesMunicipalities.push(
            this.formState.countyName
          );
        }
      } else {
        this.editor = false;
      }
    },
  },
});
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
