import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page-wrapper question-type" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_pagaTable = _resolveComponent("pagaTable")!
  const _component_popupWindow = _resolveComponent("popupWindow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_pagaTable, {
      ref: "table",
      columns: _ctx.purchaseColumns,
      sourceData: _ctx.getDataApi,
      "row-key": "goodsSpecId",
      onChange: _ctx.handleChange
    }, {
      headerLeft: _withCtx(() => [
        _createVNode(_component_a_button, {
          type: "default",
          class: "ant-btn-add",
          onClick: _ctx.handleAdd
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 新增 ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      operation: _withCtx(({ text, record }) => [
        _createVNode(_component_a_button, {
          type: "link",
          onClick: ($event: any) => (_ctx.handleClick(text, record))
        }, {
          default: _withCtx(() => [
            _createTextVNode("编辑")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_popconfirm, {
          title: "确定删除吗",
          "ok-text": "是的",
          "cancel-text": "关闭",
          onConfirm: ($event: any) => (_ctx.del(record))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, { type: "link" }, {
              default: _withCtx(() => [
                _createTextVNode("删除")
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["onConfirm"])
      ]),
      address: _withCtx(({ record }) => [
        (record.provinceName)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(record.provinceName), 1))
          : _createCommentVNode("", true),
        (record.cityName)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, " - "))
          : _createCommentVNode("", true),
        (record.cityName)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(record.cityName), 1))
          : _createCommentVNode("", true),
        (record.countyName)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, " - "))
          : _createCommentVNode("", true),
        (record.countyName)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(record.countyName), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columns", "sourceData", "onChange"]),
    _createVNode(_component_popupWindow, {
      ref: "popupWindow",
      Title: _ctx.Title
    }, null, 8, ["Title"])
  ]))
}