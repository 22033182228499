import axios from "../../axios-config";
//发货地
/**
 * 新增
 */
export function UserAddressController_post(params: any) {
  return axios.post("/api/back/mall/UserAddressController", { ...params });
}

/**
 * 修改
 */
export function UserAddressController_put(params: any) {
  return axios.put("/api/back/mall/UserAddressController", { ...params });
}

/**
 * 删除
 */
export function UserAddressController_del(params: any) {
  return axios.delete("/api/back/mall/UserAddressController", {
    params: {
      ...params
    }
  });
}
/**
 *  分页查询
 */
export function UserAddressController_page(params: any) {
  return axios.get("/api/back/mall/UserAddressController/page", {
    params: {
      ...params
    }
  });
}
/**
 * 查询所有的发货地
 */
export function UserAddressController_find(params: any) {
  return axios.get("/api/back/mall/UserAddressController/find", {
    params: {
      ...params
    }
  });
}

/**
 * 查询全国省份树
 */
export function DistrictController_findByTree(params: any) {
  return axios.get("/api/back/system/DistrictController/findByTree", {
    params: {
      ...params
    }
  });
}

